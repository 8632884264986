var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageHeader', {
    attrs: {
      "title": "Supplier List"
    }
  }), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "block block-rounded"
  }, [_vm._m(0), _c('div', {
    staticClass: "block-content"
  }, [_c('DataTable', {
    attrs: {
      "items": _vm.suppliers,
      "total": _vm.totalSuppliers,
      "loading": _vm.loadingAction.list,
      "columns": _vm.columns,
      "actions": _vm.actions,
      "shallow-search": true
    },
    scopedSlots: _vm._u([{
      key: "logoUrl",
      fn: function fn(slotProps) {
        return [_c('img', {
          staticClass: "img-avatar",
          attrs: {
            "src": slotProps.data.logoUrl,
            "alt": ""
          }
        })];
      }
    }, {
      key: "name",
      fn: function fn(slotProps) {
        return [_c('router-link', {
          staticClass: "font-w600",
          attrs: {
            "to": {
              name: 'supplier-overview',
              params: {
                id: slotProps.data._id
              }
            }
          }
        }, [_vm._v(_vm._s(slotProps.data.name))])];
      }
    }, {
      key: "countryCode",
      fn: function fn(slotProps) {
        return [_vm._v(" " + _vm._s(slotProps.data.countryCode.toUpperCase()) + " ")];
      }
    }])
  })], 1)])]), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.modals.remove,
      "title": "Delete Supplier",
      "text": "Please confirm you would like to remove supplier:<br/><br/><strong>".concat(_vm.modals.remove.name, "</strong>")
    },
    on: {
      "close": function close($event) {
        _vm.modals.remove = false;
      },
      "submit": _vm.onRemoveSupplier
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "block-header block-header-default"
  }, [_c('h3', {
    staticClass: "block-title"
  }, [_vm._v("All Suppliers")])]);
}]

export { render, staticRenderFns }