<template>
  <div>
    <PageHeader title="Supplier List" />
    <div class="content">
      <div class="block block-rounded">
        <div class="block-header block-header-default">
          <h3 class="block-title">All Suppliers</h3>
        </div>
        <div class="block-content">
          <DataTable
            :items="suppliers"
            :total="totalSuppliers"
            :loading="loadingAction.list"
            :columns="columns"
            :actions="actions"
            :shallow-search="true"
          >
            <template v-slot:logoUrl="slotProps">
              <img class="img-avatar" :src="slotProps.data.logoUrl" alt="" />
            </template>

            <template v-slot:name="slotProps">
              <router-link class="font-w600" :to="{ name: 'supplier-overview', params: { id: slotProps.data._id } }">{{
                slotProps.data.name
              }}</router-link>
            </template>

            <template v-slot:countryCode="slotProps">
              {{ slotProps.data.countryCode.toUpperCase() }}
            </template>
          </DataTable>
        </div>
      </div>
    </div>
    <ConfirmModal
      :open="!!modals.remove"
      title="Delete Supplier"
      :text="`Please confirm you would like to remove supplier:<br/><br/><strong>${modals.remove.name}</strong>`"
      @close="modals.remove = false"
      @submit="onRemoveSupplier"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import ConfirmModal from '@/components/ConfirmModal';
import DataTable from '@/components/DataTable';
import PageHeader from '@/components/PageHeader';

export default {
  name: 'SupplierList',
  components: {
    ConfirmModal,
    DataTable,
    PageHeader
  },
  data() {
    return {
      columns: [
        { name: '', code: 'logoUrl' },
        { name: 'Name', code: 'name', search: true },
        { name: 'Type', code: 'type', search: true },
        { name: 'Region', code: 'region', search: true },
        { name: 'Country', code: 'countryCode', search: true }
      ],
      modals: {
        remove: false
      }
    };
  },
  computed: {
    ...mapGetters({
      suppliers: 'supplier/suppliers',
      totalSuppliers: 'supplier/total',
      loadingAction: 'supplier/loadingAction'
    }),
    actions() {
      return [
        {
          label: 'Edit',
          class: 'btn-primary',
          icon: 'pencil',
          route: 'supplier-edit',
          params: { id: '$_id', tab: 'edit', path: 'edit' }
        },
        {
          label: 'Delete',
          class: 'btn-danger',
          icon: 'trash',
          onClick: this.onClickDeleteSupplier
        }
      ];
    }
  },
  mounted() {
    if (this.suppliers.length === 0) {
      this.refreshTable();
    }
  },
  methods: {
    ...mapActions({
      listSuppliers: 'supplier/list',
      removeSupplier: 'supplier/remove'
    }),
    onClickDeleteSupplier(item) {
      this.modals.remove = item;
    },
    async onRemoveSupplier() {
      const { _id, name } = this.modals.remove;

      await this.removeSupplier({ id: _id });
      this.refreshTable();
      this.$toasted.success(`Supplier "${name}" deleted successfully`);
      this.modals.remove = false;
    },
    refreshTable() {
      this.listSuppliers();
    }
  }
};
</script>
